<template>
  <div class="pages">
    <div class="page page1" id="page1">
      <div class="title">北向资金</div>
      <div class="time num_txt">{{ date }}</div>
      <div class="float_point float_point1">
        <img src="./imgs/point1.png" alt="" />
      </div>
      <div class="float_point float_point2">
        <img src="./imgs/point2.png" alt="" />
      </div>
      <div class="float_point float_point3">
        <img src="./imgs/point3.png" alt="" />
      </div>
      <div class="footer">
        <div class="icon_container">
          <div class="logo_tzw">
            <img src="./imgs/logo_tzw.png" alt="" />
          </div>
          <div class="logo_dy">
            <img src="./imgs/logo_dy.png" alt="" />
          </div>
        </div>
        <div class="tips">
          <p>所有展示信息仅供参考，不构成任何投资建议。</p>
          <p>投资者据此操作，风险自担。投资有风险，入市需谨慎。</p>
        </div>
      </div>
    </div>

    <div class="page page2 none" id="page2">
      <div class="page_title page2_title" id="page2_title">北向资金</div>
      <div class="chart_box">
        <div class="chart_tab">
          <div>成交净买卖额</div>
          <div>今日</div>
        </div>
        <div class="chart_line">
          <div class="sgt"><span>深股通</span></div>
          <div class="hgt"><span>沪股通</span></div>
        </div>
        <div class="line_chart_container" ref="line_chart_container">
          <line-chart
            ref="refLineChart"
            id="refLineChart"
            customClass="c_line1"
          />
        </div>
      </div>
    </div>

    <div class="page page3 none" id="page3">
      <div class="page_title page3_title" id="page3_title">北向资金</div>
      <div class="chart_box">
        <div class="chart_tab">
          <div>成交净买卖额</div>
          <div>近20日</div>
        </div>
        <div class="chart_line">
          <div class="sgt"><span>深股通</span></div>
          <div class="hgt"><span>沪股通</span></div>
        </div>
        <div class="bar_double_chart_contain" ref="bar_double_chart_container">
          <bar-double-chart
            ref="refBarDoubleChart"
            id="refBarDoubleChart"
            customClass="c_bar1"
          />
        </div>
      </div>
      <ul class="funds_in">
        <li class="li1">
          <p class="funds_in_title">20日净流入</p>
          <p
            class="funds_in_num num_txt"
            :style="{ color: flowInDataTwenty < 0 ? '#55E017' : '#FF5517' }"
          >
            <span id="num1">{{ flowInDataTwenty }}</span>
            亿
          </p>
        </li>
        <li class="li2">
          <p class="funds_in_title">5日净流入</p>
          <p
            class="funds_in_num num_txt"
            :style="{ color: flowInDataFive < 0 ? '#55E017' : '#FF5517' }"
          >
            <span id="num2">{{ flowInDataFive }}</span
            >亿
          </p>
        </li>
        <li class="li3">
          <p class="funds_in_title">3日净流入</p>
          <p
            class="funds_in_num num_txt"
            :style="{ color: flowInDataThree < 0 ? '#55E017' : '#FF5517' }"
          >
            <span id="num3">{{ flowInDataThree }}</span
            >亿
          </p>
        </li>
      </ul>
    </div>

    <div class="page page4 none" id="page4">
      <div class="page_title page4_title">
        北向资金 <span id="page4_subtitle">十大活跃榜</span>
      </div>
      <div class="chart_box chart_box1">
        <div class="chart_tab">
          <div>沪股通</div>
          <div>净流入额</div>
        </div>
        <div class="bar_chart1_contain" ref="bar_chart1_container">
          <bar-chart-horizen
            ref="refBarChart1Extra"
            id="refBarChart1Extra"
            customClass="c_bar2"
          ></bar-chart-horizen>
          <svg width="300" height="300" class="svg_gradient">
            <defs>
              <linearGradient
                id="color1"
                x1="0%"
                y1="50%"
                x2="100%"
                y2="50%"
                gradientUnits="objectBoundingBox"
              >
                <stop offset="0%" stop-color="#FF5517" stop-opacity="0.9" />
                <stop offset="100%" stop-color="#FF8B17" stop-opacity="0.9" />
              </linearGradient>
            </defs>
          </svg>
          <svg width="300" height="300" class="svg_gradient">
            <defs>
              <linearGradient
                id="color2"
                x1="0%"
                y1="50%"
                x2="100%"
                y2="50%"
                gradientUnits="objectBoundingBox"
              >
                <stop offset="0%" stop-color="#55E017" stop-opacity="0.9" />
                <stop offset="100%" stop-color="#3E9B14" stop-opacity="0.9" />
              </linearGradient>
            </defs>
          </svg>
        </div>
      </div>
    </div>

    <div class="page page5 none" id="page5">
      <div class="page_title page5_title">
        北向资金 <span id="page5_subtitle">十大活跃榜</span>
      </div>
      <div class="chart_box chart_box2">
        <div class="chart_tab">
          <div>深股通</div>
          <div>净流入额</div>
        </div>
        <div class="bar_chart2_contain" ref="bar_chart2_container">
          <bar-chart-horizen
            ref="refBarChart2Extra"
            id="refBarChart2Extra"
            customClass="c_bar3"
          ></bar-chart-horizen>
          <svg width="300" height="300" class="svg_gradient">
            <defs>
              <linearGradient
                id="color3"
                x1="0%"
                y1="50%"
                x2="100%"
                y2="50%"
                gradientUnits="objectBoundingBox"
              >
                <stop offset="0%" stop-color="#FF5517" stop-opacity="0.9" />
                <stop offset="100%" stop-color="#FF8B17" stop-opacity="0.9" />
              </linearGradient>
            </defs>
          </svg>
          <svg width="300" height="300" class="svg_gradient">
            <defs>
              <linearGradient
                id="color4"
                x1="0%"
                y1="50%"
                x2="100%"
                y2="50%"
                gradientUnits="objectBoundingBox"
              >
                <stop offset="0%" stop-color="#55E017" stop-opacity="0.9" />
                <stop offset="100%" stop-color="#3E9B14" stop-opacity="0.9" />
              </linearGradient>
            </defs>
          </svg>
        </div>
      </div>
    </div>

    <div class="page page6 none" id="page6">
      <div class="content">
        <div class="logo">
          <img src="./imgs/big_logo.png" alt="" />
        </div>
        <div class="tips" id="tips">更多内容尽在灯影</div>
      </div>
      <div class="tips2">
        <p>所有展示信息仅供参考，不构成任何投资建议。</p>
        <p>投资者据此操作，风险自担。投资有风险，入市需谨慎。</p>
      </div>
    </div>
  </div>
</template>

<script>
import { anyElement } from "@/components/anyElement.js";
import { switchPage } from "@/components/switchPage.js";
import { anyElementV1 } from "@/components/anyElement_1.js";
import { sleep, setRem, getUrlParams } from "@/utils/utils.js";
import lineChart from "@/components/lineChart/index.vue";
import barDoubleChart from "@/components/barDoubleChart/index.vue";
import barChartHorizen from "@/components/barChartHorizen/index_v1.0.vue";
import $ from "jquery";
import FontFaceObserver from "fontfaceobserver";
import ApiServe from "@/api/api";
export default {
  data() {
    return {
      model_id: null,
      model_name: "",
      ip: "",
      port: "",
      currentPage: 1,
      audioData: [],
      audioLengthList: [],
      pageOneIsPoster: true, //首页是否是封面
      hasEndLogo: true, //尾页是否是logo图

      date: null,
      currentPage: 1,
      lineData: [],
      barData: [],
      shMarket: [],
      szMarket: [],
      flowInDataThree: 0,
      flowInDataFive: 0,
      flowInDataTwenty: 0,
      pageLastTime: [1, 6, 7, 6, 6, 1], //每一页动画持续时间 由开发规定
      animationOutTime: [1, 1, 3, 0, 1, 0],
    };
  },
  watch: {
    allReady(val) {
      if (val.audioData.length > 0) {
        if (!this.ip && !this.port) {
          console.log("没获取到ip,port");
          this.page1In();
        } else {
          const params = {
            ip: this.ip,
            port: this.port,
            offset_x: this.offset_x,
            offset_y: this.offset_y,
            width: this.width,
            height: this.height,
          };
          ApiServe.recordStart(this.newIpPort, params)
            .then(({ code }) => {
              this.page1In();
            })
            .catch((err) => {
              ApiServe.recordRelease(this.newIpPort, {
                ip: this.ip,
                port: this.port,
              });
            });
        }
      }
    },
  },
  computed: {
    allReady() {
      const { dataTime, audioData } = this;
      return {
        dataTime,
        audioData,
      };
    },
    newIpPort() {
      if (this.source == "ali") {
        return "https://recordvideoali.wedengta.com";
      } else {
        return "https://recordvideo.wedengta.com";
      }
    },
    pageTime() {
      // //每一页所需要的的动画时间 消失时间 需要判断在每一页时音频时长和持续时长 取较大的那个值
      // //页面持续时长  消失动画时间; 若页面持续时长大于音频时间则直接进pageOut不需要等待
      return this.pageLastTime.map((e, i) => {
        let n =
          i == 0
            ? this.pageOneIsPoster
              ? 0
              : 1
            : i == this.pageLastTime.length - 1
            ? this.hasEndLogo
              ? 2
              : 1
            : 0; //语音只能在视频的第一秒插入所以page1时长加一秒,若pageOneIsPoster是true则不需要加1s,最后一页多留一秒
        return e > this.audioLengthList[i]
          ? e + n + 1 //1为动画出场后展示1s在出场
          : this.audioLengthList[i] + this.animationOutTime[i] + n;
      });
    },
    videoTotalTime() {
      return this.pageTime.reduce(function (prev, curr, idx, arr) {
        return prev + curr;
      }, 0);
    },
  },
  components: { lineChart, barDoubleChart, barChartHorizen },
  methods: {
    getTime() {
      const date = new Date();
      const y = date.getFullYear();
      const m =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      const d = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      this.date = y + "." + m + "." + d;
    },
    async awaitTimeLength(a, i) {
      // let t = i == this.pageTime.length - 1 ? 0 : 1;
      let time = (this.pageTime[i] - a - this.animationOutTime[i]) * 1000;
      await sleep(time); //1为动画出场的1s
    },
    async fontLoad() {
      console.log("加载字体");
      const harmonyMidium = new FontFaceObserver("harmonyMidium");
      const harmonyBold = new FontFaceObserver("harmonyBold");
      await Promise.all([
        harmonyMidium.load(null, 120 * 1000),
        harmonyBold.load(null, 120 * 1000),
      ]);
    },
    playIn1(refName) {
      let width = this.$refs.line_chart_container.clientWidth;
      this.$refs[refName].LineChart(this.lineData, {
        label: (d) => d.label,
        width: width,
        height: 420,
        marginTop: 20,
        marginBottom: 40,
        marginLeft: 40,
        marginRight: 90,
        duration: 5000,
        delay: 50,
        ease: "easeCubicInOut",
        curveName: "curveMonotoneX",
        xTickNumber: 3,
        yTickNumber: 6,
        lineColors: ["#3966FF", "#FFAF39"],
        lineWidth: [3, 3],
        isShowYAxis: false,
        numberSuffix: "亿",
      });
    },
    playIn2(refName) {
      let width = this.$refs.bar_double_chart_container.clientWidth;
      this.$refs[refName].BarChart(this.barData, {
        x: (d) => d.label,
        width: width,
        height: 500,
        marginLeft: 50,
        marginRight: 60,
        marginBottom: 40,
        zPadding: 0.05,
        duration: 400,
        delay: 100,
        yPadding: 0.95,
        ease: "easeBack",
        xTickNumber: 3,
        yTickNumber: 10,
        colors: ["#3966FF", "#FFAF39"],
        isShowText: false,
      });
    },
    playIn3(refName) {
      let width = this.$refs.bar_chart1_container.clientWidth;
      this.$refs[refName].BarChart(this.shMarket, {
        x: (d) => d.value,
        y: (d) => d.label,
        width: width,
        height: 830,
        marginLeft: 160,
        marginRight: 120,
        duration: 400,
        delay: 400,
        yPadding: 0.5,
        ease: "easeExp",
        numberSuffix: "万",
        numberPosition: "outside",
        labelPosition: "top",
        isShowRadius: true,
        barRadius: 4,
      });
    },
    playIn4(refName) {
      let width = this.$refs.bar_chart2_container.clientWidth;
      this.$refs[refName].BarChart(this.szMarket, {
        x: (d) => d.value,
        y: (d) => d.label,
        width: width,
        height: 830,
        marginLeft: 160,
        marginRight: 120,
        duration: 400,
        delay: 400,
        yPadding: 0.5,
        ease: "easeExp",
        numberSuffix: "万",
        numberPosition: "outside",
        labelPosition: "top",
        isShowRadius: true,
        barRadius: 4,
      });
    },
    playNumVaryIn(id) {
      this.number = anyElement({
        targets: document.querySelector(id),
        duration: 1200,
        toFixed: 2,
        easing: "easeOutQuint",
        inEffect: "numVaryIn",
        outEffect: "fadeOut",
      });
      this.number.goIn.restart();
    },
    async page1In() {
      console.log("page1 ", new Date());
      this.currentPage = 1;
      await sleep(1000);
      await this.awaitTimeLength(
        this.pageLastTime[this.currentPage - 1],
        this.currentPage - 1
      );

      this.page1Out();
    },
    async page1Out() {
      this.page1Title = anyElementV1({
        targets: document.querySelector(".page1 .title"),
        isSplitTxt: false,
        duration: 1000 * 2,
        delay: 1500,
        easing: "easeOutExpo",
        inEffect: "fadeIn",
        outEffect: "leftOut",
      });
      this.page1Time = anyElementV1({
        targets: document.querySelector(".page1 .time"),
        isSplitTxt: false,
        duration: 1000 * 2,
        delay: 1500,
        easing: "easeOutExpo",
        inEffect: "fadeIn",
        outEffect: "rightOut",
      });
      this.page1Point = anyElementV1({
        targets: document.querySelectorAll(".page1 .float_point"),
        isSplitTxt: false,
        duration: 1000 * 2,
        delay: 1500,
        easing: "easeOutExpo",
        inEffect: "fadeIn",
        outEffect: "fadeOut",
      });
      this.page1Footer = anyElementV1({
        targets: document.querySelector(".page1 .footer"),
        isSplitTxt: false,
        duration: 1000 * 2,
        delay: 1500,
        easing: "easeOutExpo",
        inEffect: "fadeIn",
        outEffect: "fadeOut",
      });
      this.page1Title.runOut();
      this.page1Time.runOut();
      this.page1Point.runOut();
      this.page1Footer.runOut();
      await sleep(this.animationOutTime[this.currentPage - 1] * 1000); //此处为消失动画时间
      $("#page1").addClass("none");
      this.page2In();
    },
    async page2In() {
      console.log("page2 ", new Date());
      $("#page2").removeClass("none");
      this.currentPage = 2;
      const that = this;
      this.page2Title = anyElement({
        targets: document.querySelector(".page2_title"),
        isSplitTxt: true,
        duration: 30,
        delay: 30,
        easing: "easeOutExpo",
        inEffect: "fadeIn",
        outEffect: "fadeOut",
      });

      this.page2Chart1 = anyElement({
        targets: document.querySelector(".page2 .chart_tab"),
        isSplitTxt: false,
        duration: 1000,
        delay: 1000,
        easing: "easeOutExpo",
        inEffect: "fadeIn",
        outEffect: "fadeOut",
      });
      this.page2Chart2 = anyElement({
        targets: document.querySelector(".page2 .chart_line"),
        isSplitTxt: false,
        duration: 1000,
        delay: 1000,
        easing: "easeOutExpo",
        inEffect: "fadeIn",
        outEffect: "fadeOut",
      });

      this.page2Title.goIn.restart();
      await sleep(500);
      this.page2Chart1.goIn.restart();
      this.page2Chart2.goIn.restart();
      await sleep(500);
      this.playIn1("refLineChart");
      await sleep(5000);

      await this.awaitTimeLength(
        this.pageLastTime[this.currentPage - 1],
        this.currentPage - 1
      );

      this.page2Out();
    },
    async page2Out() {
      this.page2Title.goOut.restart();
      this.page2Chart1.goOut.restart();
      this.page2Chart2.goOut.restart();
      this.$refs["refLineChart"].playOut();
      await sleep(this.animationOutTime[this.currentPage - 1] * 1000);
      $("#page2").addClass("none");
      this.page3In();
    },
    async page3In() {
      console.log("page3 ", new Date());
      $("#page3").removeClass("none");
      this.currentPage = 3;
      const that = this;
      this.page3Title = anyElement({
        targets: document.querySelector(".page3_title"),
        isSplitTxt: true,
        duration: 30,
        delay: 30,
        easing: "easeOutExpo",
        inEffect: "fadeIn",
        outEffect: "fadeOut",
      });
      this.page3Chart1 = anyElement({
        targets: document.querySelector(".page3 .chart_tab"),
        isSplitTxt: false,
        duration: 1000,
        delay: 1000,
        easing: "easeOutExpo",
        inEffect: "fadeIn",
        outEffect: "fadeOut",
      });
      this.page3Chart2 = anyElement({
        targets: document.querySelector(".page3 .chart_line"),
        isSplitTxt: false,
        duration: 1000,
        delay: 1000,
        easing: "easeOutExpo",
        inEffect: "fadeIn",
        outEffect: "fadeOut",
      });
      this.page3Funds1 = anyElement({
        targets: document.querySelector(".page3 .li1"),
        isSplitTxt: false,
        duration: 1000,
        delay: 1000,
        easing: "easeOutExpo",
        inEffect: "fadeIn",
        outEffect: "fadeOut",
      });
      this.page3Funds2 = anyElement({
        targets: document.querySelector(".page3 .li2"),
        isSplitTxt: false,
        duration: 1000,
        delay: 1000,
        easing: "easeOutExpo",
        inEffect: "fadeIn",
        outEffect: "fadeOut",
      });
      this.page3Funds3 = anyElement({
        targets: document.querySelector(".page3 .li3"),
        isSplitTxt: false,
        duration: 1000,
        delay: 1000,
        easing: "easeOutExpo",
        inEffect: "fadeIn",
        outEffect: "fadeOut",
      });

      this.page3Title.goIn.restart();
      await sleep(500);
      this.page3Chart1.goIn.restart();
      this.page3Chart2.goIn.restart();
      await sleep(500);
      this.playIn2("refBarDoubleChart");
      await sleep(2000);
      this.page3Funds1.goIn.restart();
      this.playNumVaryIn("#num1");
      await sleep(1000);
      this.page3Funds2.goIn.restart();
      this.playNumVaryIn("#num2");
      await sleep(1000);
      this.page3Funds3.goIn.restart();
      this.playNumVaryIn("#num3");

      await sleep(2000);

      await this.awaitTimeLength(
        this.pageLastTime[this.currentPage - 1],
        this.currentPage - 1
      );
      this.page3Out();
    },
    async page3Out() {
      this.$refs["refBarDoubleChart"].playOut();
      await sleep(2000);
      this.page3Title.goOut.restart();
      this.page3Chart1.goOut.restart();
      this.page3Chart2.goOut.restart();
      this.page3Funds = anyElement({
        targets: document.querySelector(".page3 .funds_in"),
        isSplitTxt: false,
        duration: 1000,
        delay: 1000,
        easing: "easeOutExpo",
        inEffect: "fadeIn",
        outEffect: "bottomOut",
      });
      this.page3Funds.goOut.restart();
      await sleep(this.animationOutTime[this.currentPage - 1] * 1000 - 2000);
      $("#page3").addClass("none");
      this.page4In();
    },
    async page4In() {
      console.log("page4 ", new Date());
      $("#page4").removeClass("none");
      this.currentPage = 4;
      const that = this;
      this.page4Title = anyElement({
        targets: document.querySelector(".page4_title"),
        isSplitTxt: false,
        duration: 400,
        delay: 400,
        easing: "easeOutExpo",
        inEffect: "fadeIn",
        outEffect: "fadeOut",
      });
      this.page4Subtitle = anyElement({
        targets: document.querySelector(".page4_title span"),
        isSplitTxt: true,
        duration: 20,
        delay: 20,
        easing: "easeOutExpo",
        inEffect: "fadeIn",
        outEffect: "fadeOut",
      });

      this.page4Chart1 = anyElement({
        targets: document.querySelector(".page4 .chart_tab"),
        isSplitTxt: false,
        duration: 1000,
        delay: 1000,
        easing: "easeOutExpo",
        inEffect: "topIn",
        outEffect: "fadeOut",
      });

      this.page4Title.goIn.restart();
      await sleep(400);
      this.page4Subtitle.goIn.restart();
      await sleep(100);
      this.page4Chart1.goIn.restart();
      await sleep(500);
      this.playIn3("refBarChart1Extra");
      await sleep(5000);

      await this.awaitTimeLength(
        this.pageLastTime[this.currentPage - 1],
        this.currentPage - 1
      );

      this.page4Out();
    },
    async page4Out() {
      // this.page4Title.goOut.restart();
      // this.page4Subtitle.goOut.restart();
      // this.page4Chart1.goOut.restart();
      // this.$refs["refBarChart1Extra"].playOut();
      // await sleep(this.animationOutTime[this.currentPage - 1] * 1000);
      // $("#page4").addClass("none");
      // this.page5In();
      switchPage({
        type: "leftOut",
        targets: "page4", //id
        duration: 500,
        ease: "ease-out",
      });
      this.page5In();
    },
    async page5In() {
      console.log("page5 ", new Date());
      $("#page5").removeClass("none");
      switchPage({
        type: "rightIn",
        targets: "page5", //id
        duration: 500,
        ease: "ease-out",
      });
      this.currentPage = 5;
      const that = this;
      this.page5Title = anyElement({
        targets: document.querySelector(".page5_title"),
        isSplitTxt: false,
        duration: 400,
        delay: 400,
        easing: "easeOutExpo",
        inEffect: "fadeIn",
        outEffect: "fadeOut",
      });
      this.page5Subtitle = anyElement({
        targets: document.querySelector(".page5_title span"),
        isSplitTxt: true,
        duration: 20,
        delay: 20,
        easing: "easeOutExpo",
        inEffect: "fadeIn",
        outEffect: "fadeOut",
      });

      this.page5Chart1 = anyElement({
        targets: document.querySelector(".page5 .chart_tab"),
        isSplitTxt: false,
        duration: 1000,
        delay: 1000,
        easing: "easeOutExpo",
        inEffect: "topIn",
        outEffect: "fadeOut",
      });

      this.page5Title.goIn.restart();
      await sleep(400);
      this.page5Subtitle.goIn.restart();
      await sleep(100);
      this.page5Chart1.goIn.restart();
      await sleep(500);
      this.playIn4("refBarChart2Extra");
      await sleep(5000);

      await this.awaitTimeLength(
        this.pageLastTime[this.currentPage - 1],
        this.currentPage - 1
      );
      this.page5Out();
    },
    async page5Out() {
      this.page5Title.goOut.restart();
      this.page5Subtitle.goOut.restart();
      this.page5Chart1.goOut.restart();
      this.$refs["refBarChart2Extra"].playOut();
      await sleep(this.animationOutTime[this.currentPage - 1] * 1000);
      $("#page5").addClass("none");
      this.page6In();
    },
    async page6In() {
      console.log("page6 ", new Date());
      this.currentPage = 6;
      $("#page6").removeClass("none");
      this.page6Logo = anyElement({
        targets: document.querySelector(".page6 .logo"),
        isSplitTxt: false,
        duration: 1000,
        delay: 1000,
        easing: "easeOutExpo",
        inEffect: "topIn",
        outEffect: "fadeOut",
      });
      this.page6Tips = anyElement({
        targets: document.querySelector(".page6 .tips "),
        isSplitTxt: true,
        duration: 30,
        delay: 30,
        easing: "easeOutExpo",
        inEffect: "fadeIn",
        outEffect: "fadeOut",
      });
      this.page6Tips2 = anyElement({
        targets: document.querySelector(".page6 .tips2 "),
        isSplitTxt: false,
        duration: 1000,
        delay: 1000,
        easing: "easeOutExpo",
        inEffect: "fadeIn",
        outEffect: "fadeOut",
      });
      this.page6Logo.goIn.restart();
      await sleep(500);
      this.page6Tips.goIn.restart();
      await sleep(500);
      this.page6Tips2.goIn.restart();

      await this.awaitTimeLength(
        this.pageLastTime[this.currentPage - 1],
        this.currentPage - 1
      );
      await this.endRecord();
    },

    async getData() {
      const that = this;
      let params = {
        model_id: this.model_id,
      };
      if (this.url_extra_params) {
        params = {
          model_id: this.model_id,
          url_extra_params: this.url_extra_params,
        };
      }
      await ApiServe.getData({ model_id: this.model_id })
        .then((res) => {
          if (res.data.code == 200) {
            let data = res.data.data;
            console.log(data);
            //page2
            const line1 = data.chart_data_1[0]?.data.fhk2sznetbuyamt,
              line2 = data.chart_data_1[0]?.data.fhk2shnetbuyamt,
              lastValue1 = data.chart_data_1[1]?.data.fsz2hknetbuyamt[0] * 1,
              lastValue2 = data.chart_data_1[1]?.data.fsh2hknetbuyamt[0] * 1,
              lineX = data.chart_data_1[0]?.data.stime;
            this.lineData = line1.map((item, index) => {
              return {
                label: lineX[index],
                value1:
                  index == line1.length - 1 ? lastValue1 : line1[index] * 1,
                value2:
                  index == line1.length - 1 ? lastValue2 : line2[index] * 1,
              };
            });

            // page3
            const bar1 = data.chart_data_2[0]?.data.fsz2hknetbuyamt,
              bar2 = data.chart_data_2[0]?.data.fsh2hknetbuyamt,
              barX = data.chart_data_2[0]?.data.sdate;
            this.barData = bar1.map((item, index) => {
              return {
                label: barX[index],
                value1: bar1[index] * 1,
                value2: bar2[index] * 1,
              };
            });
            this.flowInDataThree =
              data.chart_data_2[1]?.data.fshszbuyamt[0] * 1;
            this.flowInDataFive = data.chart_data_2[3]?.data.fshszbuyamt[0] * 1;
            this.flowInDataTwenty =
              data.chart_data_2[2]?.data.fshszbuyamt[0] * 1;

            //page4
            const shMarketName = data.chart_data_3[0]?.data.x;
            const shMarketValue = data.chart_data_3[0]?.data.y;
            this.shMarket = shMarketName.map((item, index) => {
              return {
                label: shMarketName[index],
                value: shMarketValue[index] * 1,
              };
            });

            // page5
            const szMarketName = data.chart_data_4[0]?.data.x;
            const szMarketValue = data.chart_data_4[0]?.data.y;
            this.szMarket = szMarketName.map((item, index) => {
              return {
                label: szMarketName[index],
                value: szMarketValue[index] * 1,
              };
            });

            if (this.ip) {
              ApiServe.releasePcPort({ ip: this.ip, port: this.port });
            }
          }
        })
        .catch((err) => {
          if (this.ip) {
            ApiServe.releasePcPort({ ip: this.ip, port: this.port });
          }
        });
    },
    //获取音频
    async getAudio() {
      let params = {
        model_id: this.model_id,
      };
      if (this.url_extra_params) {
        params = {
          model_id: this.model_id,
          url_extra_params: this.url_extra_params,
        };
      }
      await ApiServe.getVideoData(params)
        .then(({ data: { data, code } }) => {
          console.log(data, code);
          if (code == 200) {
            this.audioData = data;
            if (this.pageOneIsPoster) {
              // this.audioLengthList.unshift(1);
              this.audioData.unshift({
                audio_content: "",
                audio_length: 0,
                audio_link: "",
                scene_duration: 1,
                scene_id: "0",
              });
            }
            if (this.hasEndLogo) {
              this.audioData.push({
                audio_content: "",
                audio_length: 2,
                audio_link: "",
                scene_duration: 2,
                scene_id: "0",
              });
            }
            this.audioLengthList = this.audioData.map(
              (item) => item.scene_duration
            );

            if (this.ip && this.audioData.length == 0) {
              ApiServe.recordRelease({ ip: this.ip, port: this.port });
            }
          }
        })
        .catch((err) => {
          if (this.ip) {
            ApiServe.releasePcPort({ ip: this.ip, port: this.port });
          }
        });
    },
    async endRecord() {
      let arr = [];
      for (let i = 0; i < this.pageTime.length; i++) {
        if (i == 0) {
          this.pageOneIsPoster ? (arr[i] = 2) : (arr[i] = 1);
        } else {
          arr[i] = this.pageTime[i - 1] + arr[i - 1];
        }
      }
      let audiosOrderList = this.audioData.map((item, index) => {
        return {
          audioLink: item.audio_link,
          timeIndex: this.pageOneIsPoster
            ? index == 1
              ? 2
              : arr[index] - 2
            : arr[index],
          audioLen: item.audio_length,
        };
      });
      if (this.pageOneIsPoster) {
        audiosOrderList.shift();
      }
      if (this.hasEndLogo) {
        audiosOrderList.pop();
      }
      const data = {
        id: this.id,
        model_id: this.model_id,
        videoTotalTime: this.videoTotalTime,
        videmBgm:
          "https://idtcdn.oss-cn-hangzhou.aliyuncs.com/Cube/VideoBgm/bg.mp3",
        audiosOrderList: audiosOrderList,
      };
      console.log("arr", arr);
      console.log("audiosOrderList", audiosOrderList);
      console.log("pageTime", this.pageTime);
      console.log("pageLastTime", this.pageLastTime);
      console.log("audioLengthList", this.audioLengthList);
      console.log("videoTotalTime", this.videoTotalTime);
      await ApiServe.recordStop(this.newIpPort, {
        ip: this.ip,
        port: this.port,
      })
        .then(() => {
          ApiServe.combineAudiosWithBgm(
            this.newIpPort,
            this.ip,
            this.port,
            data
          )
            .then(() => {
              ApiServe.recordRelease(this.newIpPort, {
                ip: this.ip,
                port: this.port,
              });
            })
            .catch(() => {
              ApiServe.recordRelease(this.newIpPort, {
                ip: this.ip,
                port: this.port,
              });
            });
        })
        .catch(() => {
          ApiServe.recordRelease(this.newIpPort, {
            ip: this.ip,
            port: this.port,
          });
        });
    },
  },
  async mounted() {
    this.model_id = 70;
    this.id = getUrlParams().id || null;
    this.model_name = getUrlParams().model_name || null;
    this.model_category = getUrlParams().model_category || null;
    this.url_extra_params = getUrlParams().url_extra_params || null;
    this.ip = getUrlParams().ip || null;
    this.port = getUrlParams().port || null;
    this.offset_x = getUrlParams().offset_x || null;
    this.offset_y = getUrlParams().offset_y || null;
    this.width = getUrlParams().width || null;
    this.height = getUrlParams().height || null;
    this.source = getUrlParams().source || null;
    //每个项目都可以独立设置
    setRem(720);

    this.getTime();

    //字体加载比较慢，等它加载完
    await this.fontLoad();
    await this.getData();
    await this.getAudio();
  },
};
</script>

<style lang="less" scoped>
@import "index.less";
</style>